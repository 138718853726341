// HomeHunter.jsx
import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import api from '../components/Hunt/api.js';
import '../scss/component/_section.scss';
import '../scss/component/_huntgroup.scss';
import TopBar from '../components/mapbox/TopBar.js';
import SpaceTopBar from '../components/SpaceTopBar/index.jsx';
import HuntGroupList from '../components/Hunt/HuntGroup/HuntGroupList';
import CreateHuntGroup from '../components/Hunt/HuntGroup/CreateHuntGroup';
import HuntGroupDetails from '../components/Hunt/HuntGroup/HuntGroupDetails';
import Map from '../components/Hunt/Map/Map';
import Chat from '../components/Hunt/Chat/Chat';
import AnimalSpotting from '../components/Hunt/AnimalSpotting/AnimalSpotting';
import HuntingGrounds from '../components/Hunt/HuntingGrounds/HuntingGrounds'; // Import HuntingGrounds component
import Invitations from '../components/Hunt/Invitations/Invitations'; // Import the Invitations component




function HomeHunter(props) {
  const [username, setUsername] = useState(localStorage.getItem('UserEmail') || '');
  const [huntGroups, setHuntGroups] = useState([]);
  const [selectedHuntGroup, setSelectedHuntGroup] = useState(
    JSON.parse(localStorage.getItem('selectedHuntGroup')) || null
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchHuntGroups = async () => {
      try {
        const token = localStorage.getItem('token');
        const groups = await api.getHuntGroups(token);
        if (Array.isArray(groups)) {
          setHuntGroups(groups);
          if (id) {
            const storedHuntGroup = groups.find(group => group.huntGroupId === parseInt(id, 10));
            if (storedHuntGroup) setSelectedHuntGroup(storedHuntGroup);
          }
        } else {
          console.error('API response is not an array:', groups);
          setError('Failed to fetch hunt groups.'); // Set error message
        }
      } catch (error) {
        console.error('Error fetching hunt groups:', error);
        setError(error); // Set error state
      } finally {
        setLoading(false);
      }
    };

    fetchHuntGroups();
  }, [id]);

  const handleLogout = () => {
    // ... your logout logic ...
  };

  const handleCreateHuntGroup = async (huntGroupName) => {
    try {
      const token = localStorage.getItem('token');
      const newGroup = await api.createHuntGroup(huntGroupName, token);
      setHuntGroups([...huntGroups, newGroup]);
      localStorage.setItem('selectedHuntGroup', JSON.stringify(newGroup));
      navigate(`/homehunter/huntgroups/${newGroup.huntGroupId}`); // Redirect to the new hunt group
    } catch (error) {
      console.error('Error creating hunt group:', error);
      // Handle error (e.g., show an error message)
    }
  };

  const handleHuntGroupSelect = (huntGroup) => {
    setSelectedHuntGroup(huntGroup);
    localStorage.setItem('selectedHuntGroup', JSON.stringify(huntGroup));
    navigate(`/homehunter/huntgroups/${huntGroup.huntGroupId}`);
  };

  return (
    <div className="header-fixed main home1 counter-scroll inner-page"> 
      <SpaceTopBar />
      {/* ... (conditional rendering) */}
      <Routes>
        <Route path="/" element={
          <div className="tf-section project"> {/* Added tf-section and project classes */}
            <div className="container"> {/* Added container class */}
              <div className="tf-title"> {/* Added tf-title class */}
                <h2 className="title">My Hunt Groups</h2> {/* Added title class to h2 */}
              </div>
              <HuntGroupList huntGroups={huntGroups} onSelect={handleHuntGroupSelect} />
              <CreateHuntGroup onCreate={handleCreateHuntGroup} />
            </div>
          </div>
        } />
        <Route path="/huntgroups/:id" element={
          <div className="tf-section project"> {/* Added tf-section and project classes */}
            <div className="container"> {/* Added container class */}
              {selectedHuntGroup && (
                <>
                  <HuntGroupDetails huntGroup={selectedHuntGroup} />
                </>
              )}
            </div>
          </div>
        } />
        {/* ... (your other routes) ... */}
      </Routes>
    </div>
  );
}

export default HomeHunter;
