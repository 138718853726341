// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import api from './components/Hunt/api'; // Adjust the path if needed


const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [user, setUser] = useState(null);


  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      // You might want to verify the token here with your backend
      setIsLoggedIn(true);
      setUser({
        email: localStorage.getItem('UserEmail'),
        id: localStorage.getItem('UserId'),
        name: localStorage.getItem('UserName')
      });
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const login = async (email, password) => {
    try {
      const response = await api.loginUser({
        UserEmail: email,
        UserPassword: password,
      });

      localStorage.setItem('token', response.token);
      localStorage.setItem('UserEmail', response.userEmail);
      localStorage.setItem('UserId', response.userId);
      localStorage.setItem('UserName', response.userName);

      setIsLoggedIn(true);
      setUser({
        email: response.userEmail,
        id: response.userId,
        name: response.userName
      });
    } catch (error) {
      console.error('Login failed:', error);
      throw error; // Re-throw the error
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('UserEmail');
    localStorage.removeItem('UserId');
    localStorage.removeItem('UserName');
    setIsLoggedIn(false);
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };