// src/components/Hunt/HuntGroup/HuntGroupDetails.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../api';
import Map from '../Map/Map';
import Chat from '../Chat/Chat';
import '../../../scss/component/_huntgroup.scss';
import InviteMember from './InviteMember';
import RemoveMember from './RemoveMember';

const HuntGroupDetails = ({ huntGroup }) => {
  const { id } = useParams();
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [availableHuntingGrounds, setAvailableHuntingGrounds] = useState([]);
  const [assignedHuntingGrounds, setAssignedHuntingGrounds] = useState([]);
  const [selectedHuntingGroundId, setSelectedHuntingGroundId] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleDeleteHuntGroup = async (huntGroupId) => {
    try {
      const token = localStorage.getItem('token');
      await api.deleteHuntGroup(huntGroupId, token);
      // Redirect to the hunt group list after successful deletion
      navigate('/homehunter'); // Or any other appropriate route
    } catch (error) {
      console.error('Error deleting hunt group:', error);
      setError('Failed to delete hunt group.');
    }
  };



  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const token = localStorage.getItem('token');
        const membersData = await api.getHuntGroupMembers(id, token);
        if (Array.isArray(membersData)) {
          setMembers(membersData);
        } else {
          console.error('API response for members is not an array:', membersData);
          setMembers([]);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchMembers();
  }, [id]);

  useEffect(() => {
    // Fetch available and assigned hunting grounds for the user and this hunt group
    const fetchHuntingGrounds = async () => {
      try {
        const token = localStorage.getItem('token');
        const available = await api.getHuntingGrounds(token);
        const assigned = await api.getAssignedHuntingGrounds(id, token); // Fetch assigned hunting grounds
        setAvailableHuntingGrounds(available);
        setAssignedHuntingGrounds(assigned);
      } catch (error) {
        console.error('Error fetching hunting grounds:', error);
      }
    };

    fetchHuntingGrounds();
  }, [id]); // Re-fetch when huntGroupId (id) changes

  const handleAssignHuntingGround = async () => {
    if (!selectedHuntingGroundId) {
      setError('Please select a hunting ground to assign.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await api.assignHuntingGroundToHuntGroup(id, selectedHuntingGroundId, token);
      // Update available and assigned hunting grounds after assigning
      const updatedAvailable = availableHuntingGrounds.filter(
        (ground) => ground.huntingGroundId !== selectedHuntingGroundId
      );
      const updatedAssigned = [...assignedHuntingGrounds, availableHuntingGrounds.find((ground) => ground.huntingGroundId === selectedHuntingGroundId)];
      setAvailableHuntingGrounds(updatedAvailable);
      setAssignedHuntingGrounds(updatedAssigned);
      setSelectedHuntingGroundId(null); // Clear selection
      setError(null); // Clear any previous errors
    } catch (error) {
      console.error('Error assigning hunting ground:', error);
      setError('Failed to assign hunting ground.');
    }
  };

  const handleRemoveHuntingGround = async (huntingGroundId) => {
    try {
      const token = localStorage.getItem('token');
      await api.removeHuntingGroundFromHuntGroup(id, huntingGroundId, token);
      // Update available and assigned hunting grounds after removing
      const updatedAssigned = assignedHuntingGrounds.filter(
        (ground) => ground.huntingGroundId !== huntingGroundId
      );
      const updatedAvailable = [...availableHuntingGrounds, assignedHuntingGrounds.find((ground) => ground.huntingGroundId === huntingGroundId)];
      setAssignedHuntingGrounds(updatedAssigned);
      setAvailableHuntingGrounds(updatedAvailable);
      setError(null); // Clear any previous errors
    } catch (error) {
      console.error('Error removing hunting ground:', error);
      setError('Failed to remove hunting ground.');
    }
  };

  if (loading) {
    return <div className="spinner"></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="tf-section project"> {/* Added tf-section and project classes */}
      <div className="container page-content-container"> {/* Added container class */}
        <div className="tf-title"> {/* Added tf-title class */}
          <h2 className="title">{huntGroup.name}</h2> {/* Added title class to h2 */}
          <p>Invite Code: {huntGroup.inviteCode}</p>
        </div>

      <h3>Members</h3>
      <ul className="huntgroup-list">
        {members.length > 0 ? (
          members.map((member) => (
            <li key={member.userId}>{member.userName}</li>
          ))
        ) : (
          <p>No members in this group yet.</p>
        )}
      </ul>
      {/* Add a Delete button */}
      <button onClick={() => handleDeleteHuntGroup(huntGroup.huntGroupId)}>
        Delete Hunt Group
      </button>
      {/* Invite and Remove Member components */}
      <InviteMember huntGroupId={huntGroup.huntGroupId} />
      <RemoveMember huntGroupId={huntGroup.huntGroupId} members={members} /> {/* Pass members data */}

      {/* Hunting Grounds Section */}
      <h3>Hunting Grounds</h3>

      {/* Assign Hunting Ground */}
      <div>
        <select
          value={selectedHuntingGroundId || ''}
          onChange={(e) => setSelectedHuntingGroundId(parseInt(e.target.value, 10))}
        >
          <option value="">Select a hunting ground to assign</option>
          {availableHuntingGrounds.map((ground) => (
            <option key={ground.huntingGroundId} value={ground.huntingGroundId}>
              {ground.name}
            </option>
          ))}
        </select>
        <button onClick={handleAssignHuntingGround} disabled={!selectedHuntingGroundId}>
          Assign
        </button>
      </div>

      {/* Assigned Hunting Grounds */}
      <ul>
        {assignedHuntingGrounds.map((ground) => (
          <li key={ground.huntingGroundId}>
            {ground.name}
            <button onClick={() => handleRemoveHuntingGround(ground.huntingGroundId)}>
              Remove
            </button>
          </li>
        ))}
      </ul>

      {/* Map and Chat components */}
      <div className="huntgroup-details-container"> {/* Add the container */}
          <div className="huntgroup-map"> {/* Add the map container */}
            <Map huntGroupId={id} />
          </div>
          <div className="huntgroup-details"> {/* Add the details container */}
          <Chat huntGroupId={id} /> {/* Keep the chat within details */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HuntGroupDetails;