// InviteMember.jsx
import React, { useState } from 'react';
import api from '../api';

const InviteMember = ({ huntGroupId }) => {
  const [email, setEmail] = useState(''); // Use email instead of inviteCode
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleInvite = async () => {
    try {
      const token = localStorage.getItem('token');
      await api.inviteUserToHuntGroupByEmail(huntGroupId, email, token); // Call the new API method
      setSuccess(true);
      setEmail('');
      setError(null);
    } catch (error) {
      console.error('Error inviting user:', error);
      setError('Failed to invite user.');
      setSuccess(false);
    }
  };

  return (
    <div>
      <h3>Invite Member</h3>
      {success && <div className="success-message">Invitation sent successfully!</div>}
      <input
        type="email" // Use type="email" for email input
        placeholder="Enter email address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button onClick={handleInvite} disabled={!email}>
        Invite
      </button>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default InviteMember;