// RemoveMember.jsx
import React, { useState, useEffect } from 'react';
import api from '../api';

const RemoveMember = ({ huntGroupId }) => {
  const [members, setMembers] = useState([]);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false); // To show success message

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const token = localStorage.getItem('token');
        const membersData = await api.getHuntGroupMembers(huntGroupId, token);
        setMembers(membersData);
      } catch (error) {
        console.error('Error fetching members:', error);
        setError('Failed to fetch members.');
      }
    };

    fetchMembers();
  }, [huntGroupId]);

  const handleRemove = async () => {
    if (!selectedMemberId) {
      setError('Please select a member to remove.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await api.removeUserFromHuntGroup(huntGroupId, selectedMemberId, token);
      setSuccess(true); // Show success message
      // Update the members list after removing
      setMembers(members.filter((member) => member.userId !== selectedMemberId));
      setSelectedMemberId(null); // Clear selection
      setError(null); // Clear any previous errors
    } catch (error) {
      console.error('Error removing member:', error);
      setError('Failed to remove member.');
      setSuccess(false); // Hide success message in case of error
    }
  };

  return (
    <div>
      <h3>Remove Member</h3>
      {success && <div className="success-message">Member removed successfully!</div>} {/* Success message */}
      <select
        value={selectedMemberId || ''} // Set value to empty string if null
        onChange={(e) => setSelectedMemberId(parseInt(e.target.value, 10))}
      >
        <option value="">Select a member</option>
        {members.map((member) => (
          <option key={member.userId} value={member.userId}>
            {member.userName}
          </option>
        ))}
      </select>
      <button onClick={handleRemove} disabled={!selectedMemberId}>
        Remove
      </button>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default RemoveMember;