// src/components/Hunt/HuntGroup/HuntGroupList.js
import React from 'react';

const HuntGroupList = ({ huntGroups, onSelect }) => {
  return (
    <ul className="huntgroup-list">
      {huntGroups.length > 0 ? (
        huntGroups.map((group) => (
          <li key={group.huntGroupId} onClick={() => onSelect(group)}>
            <h3>{group.name}</h3>
            <p>Invite Code: {group.inviteCode}</p>
          </li>
        ))
      ) : (
        <p>You have no hunt groups yet. Create one to get started!</p>
      )}
    </ul>
  );
};

export default HuntGroupList;