// AppAuth.js
import React, { useContext } from 'react'; // Remove useEffect
import { AuthContext } from './AuthContext';

import Header from './components/header';
import HeaderPublic from './components/headerPublic';
import Footer from './components/footer';
import FooterPublic from './components/footerPublic';
import routes from './pages/index';
import routesPublic from './pages/indexPublic';
import { Route, Routes } from 'react-router-dom';

function AppAuth() {
  const { isLoggedIn } = React.useContext(AuthContext);



  return (
    <>
      {isLoggedIn ? <Header /> : <HeaderPublic />}

      <Routes>
        {isLoggedIn
          ? routes.map((data, idx) => (
              <Route key={idx} path={data.path} element={data.component} exact />
            ))
          : routesPublic.map((data, idx) => (
              <Route key={idx} path={data.path} element={data.component} exact />
            ))}
      </Routes>

      {isLoggedIn ? <Footer /> : <FooterPublic />}
    </>
  );
}

export default AppAuth;