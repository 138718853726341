import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import api from '../components/mapbox/api'; // Import your API class
import { useNavigate } from 'react-router-dom';
import config from '../config.js'; // Import your config file
import { GoogleOAuthProvider } from '@react-oauth/google';

function LoginGoogle() {
  const navigate = useNavigate();

  const onSuccess = async (res) => {
    try {
      const response = await api.googleAuth({
        UserEmail: res.credential.email,
        UserName: res.credential.name, // Assuming Google provides the name
      });

      // Store token and user data in localStorage
      localStorage.setItem('token', response.token);
      localStorage.setItem('UserEmail', response.userEmail);
      localStorage.setItem('UserId', response.userId);
      localStorage.setItem('UserName', response.userName);

      navigate('/'); // Redirect to the main page
    } catch (error) {
      console.error('Google Auth failed:', error);
      // Handle error, e.g., display an error message
    }
  };

  const onFailure = (err) => {
    console.error('Google Auth failed:', err);
    // Handle error, e.g., display an error message
  };

  return (
    <GoogleOAuthProvider clientId='486665960045-s2s51daud3grbbicqfo7e1qdua03dcvq.apps.googleusercontent.com'> 
    <GoogleLogin
      onSuccess={onSuccess}
      onError={onFailure}
    />
   </GoogleOAuthProvider>
  );
}

export default LoginGoogle;