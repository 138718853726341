// src/components/Hunt/Map/Map.js
import React, { useState, useEffect, useRef } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import api from '../api'; 
import deerIcon from './MapMarker_Image/deer100x100.png'; 
import hunterIcon from './MapMarker_Image/gps100x100.png'; 

const Map = ({ huntGroupId }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [animals, setAnimals] = useState([]);
  const [hunterPositions, setHunterPositions] = useState([]);
  const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, latlng: null });
  const [showAnimalSelection, setShowAnimalSelection] = useState(false);

  const fetchAnimals = async () => {
    try {
      const token = localStorage.getItem('token');
      const animalData = await api.getAnimalSpottings(huntGroupId, token);
      if (Array.isArray(animalData)) {
        setAnimals(animalData.filter(animal => !isNaN(animal.latitude) && !isNaN(animal.longitude)));
      } else {
        console.error('API response for animal spottings is not an array:', animalData);
        setAnimals([]);
      }
    } catch (err) {
      console.error('Error fetching animals:', err);
    }
  };

  const fetchHuntPositions = async () => {
    try {
      const token = localStorage.getItem('token');
      const positionsData = await api.getHuntPositions(huntGroupId, token);
      if (Array.isArray(positionsData)) {
        setHunterPositions(positionsData.filter(hunter => !isNaN(hunter.latitude) && !isNaN(hunter.longitude)));
      } else {
        console.error('API response for hunt positions is not an array:', positionsData);
        setHunterPositions([]);
      }
    } catch (err) {
      console.error('Error fetching hunt positions:', err);
    }
  };

  useEffect(() => {
    const fetchHuntingGround = async () => {
      try {
        const token = localStorage.getItem('token');
        const huntingGround = await api.getHuntingGroundForHuntGroup(huntGroupId, token);

        if (huntingGround) {
          const coordinates = JSON.parse(huntingGround.coordinates);

          if (huntingGround.type === 'point') {
            map.current.setView([coordinates.latitude, coordinates.longitude], 12);
          } else if (huntingGround.type === 'polygon') {
            const polygon = L.polygon(coordinates).addTo(map.current);
            map.current.fitBounds(polygon.getBounds());
          }
        } else {
          // If no hunting ground is assigned, try to get user's location
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
              const { latitude, longitude } = position.coords;

              map.current.setView([latitude, longitude], 12); 
            });
          } else {
            // Geolocation is not supported, set a default location
            map.current.setView([59.9139, 10.7522], 13); // Oslo, Norway as default
          }
        }
      } catch (error) {
        console.error('Error fetching hunting ground:', error);
      }
    };

    fetchHuntingGround();
  }, [huntGroupId]);

  useEffect(() => {
    fetchAnimals();
    fetchHuntPositions();

    const intervalId = setInterval(() => {
      fetchAnimals();
      fetchHuntPositions();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [huntGroupId]);

  useEffect(() => {
    if (!map.current) {
      map.current = L.map(mapContainer.current).setView([50, 10], 4); 

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map.current); 


      // Create custom icons
      const deerMarker = L.icon({
        iconUrl: deerIcon,
        iconSize: [32, 32], 
        iconAnchor: [16, 32], 
      });

      const hunterMarker = L.icon({
        iconUrl: hunterIcon,
        iconSize: [32, 32], 
        iconAnchor: [16, 32], 
      });

      // Add initial markers for animals
      animals.forEach((animal) => {
        if (animal.latitude && animal.longitude) {
          L.marker([animal.latitude, animal.longitude], { icon: deerMarker })
            .bindPopup(`<strong>Animal:</strong> ${animal.animalType}<br>`)
            .addTo(map.current);
        }
      });

      // Add initial markers for hunter positions
      hunterPositions.forEach((position) => {
        if (position.latitude && position.longitude) {
          L.marker([position.latitude, position.longitude], { icon: hunterMarker })
            .addTo(map.current);
        }
      });

      // Context menu event
      map.current.on('contextmenu', (event) => {
        setContextMenu({ 
          visible: true, 
          x: event.originalEvent.clientX, 
          y: event.originalEvent.clientY, 
          latlng: event.latlng 
        });
      });
    }
  }, []);

  useEffect(() => {
    if (map.current) {
      // Clear existing markers
      map.current.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          map.current.removeLayer(layer);
        }
      });

      // Create custom icons
      const deerMarker = L.icon({
        iconUrl: deerIcon,
        iconSize: [32, 32], 
        iconAnchor: [16, 32], 
      });

      const hunterMarker = L.icon({
        iconUrl: hunterIcon,
        iconSize: [32, 32], 
        iconAnchor: [16, 32], 
      });

      // Add updated markers for animals
      animals.forEach((animal) => {
        if (animal.latitude && animal.longitude) {
          L.marker([animal.latitude, animal.longitude], { icon: deerMarker })
            .bindPopup(`<strong>Animal:</strong> ${animal.animalType}<br>`)
            .addTo(map.current);
        }
      });

      // Add updated markers for hunter positions
      hunterPositions.forEach((position) => {
        if (position.latitude && position.longitude) {
          L.marker([position.latitude, position.longitude], { icon: hunterMarker })
            .addTo(map.current);
        }
      });
    }
  }, [animals, hunterPositions]);


  const handleContextMenuClose = () => {
    setContextMenu({ visible: false, x: 0, y: 0, latlng: null });
    setShowAnimalSelection(false);
  };

  const handleMarkAnimal = () => {
    setShowAnimalSelection(true);
    setContextMenu((prev) => ({ ...prev, visible: false }));
  };

  const handleAnimalSelection = async (animalType) => {
    const { latlng } = contextMenu; // Use latlng from contextMenu
    if (latlng) {
      const token = localStorage.getItem('token');
      const spottingData = {
        huntGroupId,
        latitude: latlng.lat, 
        longitude: latlng.lng, 
        animalType,
        notes: '', 
      };
      try {
        await api.addAnimalSpotting(spottingData, token);
        setAnimals((prev) => [...prev, spottingData]);
      } catch (error) {
        console.error('Error adding animal spotting:', error);
      }
    }
    handleContextMenuClose();
  };

  const handleMarkHunter = () => {
    const { latlng } = contextMenu; // Use latlng from contextMenu
    if (latlng) {
      console.log('Marking hunter point at:', latlng); 
    }
    handleContextMenuClose();
  };

  return (
    <div className="tf-section"> {/* Added tf-section class */}
      <div ref={mapContainer} style={{ width: '100%', height: '400px' }} /> 


      {/* Context menu */}
      {contextMenu.visible && !showAnimalSelection && (
        <div 
          className="context-menu"
          style={{
            position: 'absolute',
            top: contextMenu.y,
            left: contextMenu.x,
            background: 'white',
            border: '1px solid black',
            zIndex: 1,
          }}
        >
          <ul>
            <li onClick={handleMarkAnimal}>Mark Animal Position</li>
            <li onClick={handleMarkHunter}>Mark Hunter Point</li>
          </ul>
        </div>
      )}

      {/* Animal selection menu */}
      {showAnimalSelection && (
        <div 
          className="animal-selection"
          style={{
            position: 'absolute',
            top: contextMenu.y,
            left: contextMenu.x,
            background: 'white',
            border: '1px solid black',
            zIndex: 1,
          }}
        >
          <ul>
            {['Deer', 'Bear', 'Boar'].map((animal) => (
              <li key={animal} onClick={() => handleAnimalSelection(animal)}>
                {animal}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Map;