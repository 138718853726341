import React from 'react';



function SpaceTopBar(props) {

    return (
        <div>

            <div style={{ paddingTop: '120px' }}>
                {
                    // THIS MAKES SPACE FROM TOP BAR
                }
            </div>


        </div>
    );
}

export default SpaceTopBar;