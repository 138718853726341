// src/components/Hunt/Chat/Chat.js
import React, { useState, useEffect, useRef } from 'react';
import api from '../api';

const Chat = ({ huntGroupId }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef(null);
  const intervalId = useRef(null); // Use a ref to store the interval ID


  useEffect(() => {
    const seenMessageIds = new Set(); // Keep track of seen message IDs

    const fetchMessages = async () => {
      try {
        const token = localStorage.getItem('token');
        const messagesData = await api.getMessages(huntGroupId, token);

        const uniqueMessages = messagesData.filter(message => {
          if (seenMessageIds.has(message.messageId)) {
            return false; // Filter out duplicates
          } else {
            seenMessageIds.add(message.messageId);
            return true;
          }
        });

        setMessages(uniqueMessages);
      } catch (error) {
        console.error('Error fetching messages:', error);
        setMessages([]);
      }
    };

    fetchMessages();

    intervalId.current = setInterval(async () => {
      try {
        const token = localStorage.getItem('token');
        const newMessagesData = await api.getMessages(huntGroupId, token);

        const uniqueNewMessages = newMessagesData.filter(message => {
          if (seenMessageIds.has(message.messageId)) {
            return false;
          } else {
            seenMessageIds.add(message.messageId);
            return true;
          }
        });

        if (uniqueNewMessages.length > 0) {
          setMessages(prevMessages => [...prevMessages, ...uniqueNewMessages]);
        }
      } catch (error) {
        console.error('Error fetching new messages:', error);
      }
    }, 5000);

    return () => clearInterval(intervalId.current);
  }, [huntGroupId]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (newMessage.trim() !== '') {
      const token = localStorage.getItem('token');
      const message = {
        huntGroupId: huntGroupId,
        text: newMessage,
      };
      try {
        await api.sendMessage(huntGroupId, message, token);
        setNewMessage(''); // Clear the input field
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  return (
    <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
      <h3>Chat</h3>
      <div className="chat-messages">
        {messages.length > 0 ? (
          messages.map((message) => (
            <div key={message.messageId}>
              <b>{message.senderId}:</b> {message.messageText} - {message.timestamp.toLocaleString()}
            </div>
          ))
        ) : (
          <p>No messages yet. Start the conversation!</p>
        )}
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message..."
        />
        <button type="submit">Send</button>
      </form>
    </div>
  );
};



export default Chat;