// src/components/header.jsx
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { Link, NavLink } from 'react-router-dom';
import menus from '../../pages/menu';
import Button from '../button/index';
import './styles.scss';
import logo from '../../assets/images/logo/logo-removebg_100x100.png'

const Header = () => {
    const { isLoggedIn, logout, user } = useContext(AuthContext);

    const handleLogout = () => {
        logout();
    };

    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            setScroll(window.scrollY > 300);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const [menuActive, setMenuActive] = useState(null);
    const handleMenuActive = () => {
        setMenuActive(!menuActive);
    };

    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index);
    };

    return (
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
            <div className="container">
                <div id="site-header-inner">
                    <div className="header__logo">
                        <NavLink to="/"><img src={logo} alt="exh" /></NavLink>
                    </div>

                    <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`} >
                        <ul id="menu-primary-menu" className="menu">
                            {menus.map((data, idx) => (
                                <li
                                    key={idx}
                                    onClick={() => {
                                        handleDropdown(idx);
                                        handleMenuActive();
                                    }} // Close menu on click
                                    className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`}
                                >
                                    <Link to={data.links}>{data.name}</Link>
                                    {data.namesub && (
                                        <ul className="sub-menu">
                                            {data.namesub.map((submenu) => (
                                                <li key={submenu.id} className="menu-item">
                                                    <NavLink to={submenu.links} onClick={handleDropdown}>
                                                        {submenu.sub}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </nav>


                    {isLoggedIn && user ? (
                        <div>
                            <p>Welcome, {user.name}!</p>
                            <button onClick={handleLogout} className="style1">
                                Sign Out
                            </button>
                        </div>
                    ) : (
                        <Button title="Connect" path="/" addclass="style1" />
                    )}

                    <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}>
                        <span></span>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;