import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, useNavigate } from "react-router-dom";
import './App.scss';
import ScrollToTop from "./ScrollToTop";
import { AuthProvider, AuthContext } from './AuthContext';
import AppAuth from './AppAuth';
import App from './App';
import AppPublic from './AppPublic';






const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider> {/* Make sure AuthProvider is rendered first */}
      <BrowserRouter>
        <ScrollToTop />
        <AppAuth />
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);