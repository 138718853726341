// src/config.js


const config = {
  apiHunter: 'https://api.mosquito.network:5443/api',
  GoogleClientId: '486665960045-s2s51daud3grbbicqfo7e1qdua03dcvq.apps.googleusercontent.com',
  GoogleClientSecret: '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'
};


export default config;