import img1 from '../images/slider/Furore.png';
import img2 from '../images/logo/no_background/gps-removebg-preview_100x100.png';
import img3 from '../images/logo/no_background/paw0-removebg-preview_100x100.png';
import img4 from '../images/logo/no_background/scope-removebg-preview_100x100.png';
import img5 from '../images/logo/no_background/deer-removebg-preview_100x100.png';
import img6 from '../images/logo/no_background/rifle-removebg-preview_100x100.png';

const dataBanner = [
    {
        id: 1,
        heading: 'Pro Hunting, The Hunter¨s Forge',
        sub_heading: 'Visually and spatially connecting hunters and accessory in a seamless experience',
        img1: img5,
        img2: img2,
        img3: img3,
        img4: img4,
    }, 
    {
        id: 2,
        heading: 'Digital Hunting Companion',
        sub_heading: 'GPS Hunt Connect, Hunter Locator & Route Planner, The Hunting Network, Shared Hunting Grounds, ',
        img1: img5,
        img2: img2,
        img3: img3,
        img4: img4,
    }
]

export default dataBanner;