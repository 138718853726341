// src/pages/index.js
import HomePrivate from "./HomePrivate";
//import HomePrivateMap from "../components/mapbox/HomePrivateMap";
import UserProfilePrivate from "./UserProfilePrivate";

import HomeHunter from "./HomeHunter"; 
import HuntGroupList from '../components/Hunt/HuntGroup/HuntGroupList';
import CreateHuntGroup from '../components/Hunt/HuntGroup/CreateHuntGroup';
import HuntGroupDetails from '../components/Hunt/HuntGroup/HuntGroupDetails';
import JoinHuntGroup from '../components/Hunt/HuntGroup/JoinHuntGroup';
import Map from '../components/Hunt/Map/Map';
import Chat from '../components/Hunt/Chat/Chat';
import AnimalSpotting from '../components/Hunt/AnimalSpotting/AnimalSpotting';


const routes = [
  
  { path: '/', component: <HomePrivate />},
  { path: '/homeprivate', component: <HomePrivate />},
  { path: '/userprofile', component: <UserProfilePrivate />},


  { path: '/homehunter/*', component: <HomeHunter />},




]

export default routes;