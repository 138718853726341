import React from 'react';
import '../scss/component/_section.scss';
import '../scss/component/_box.scss';
import '../scss/component/_tf-section.scss';
import '../scss/component/_reponsive.scss';
import Locations from '../components/locations/index.jsx';
import backgroundImage from '../images/bg.jpeg'; // Adjust the path if needed

import Banner from '../components/bannerPublic/banner_v1';
import dataBanner from '../assets/fake-dataPublic/data-banner';



function HomePublic(props) {

    return (
        <div className='header-fixed main home1 counter-scroll' >

            {<Banner data={dataBanner} />}


        </div>
    );
}

export default HomePublic;