const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/homeprivate',
    },
    {
        id: 3,
        name: 'Hunt',
        links: '/homehunter',
    },
    {
        id: 4,
        name: 'Userprofile',
        links: '/userprofile',
    },
]

export default menus;