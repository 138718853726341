// src/components/mapbox/api.js
import axios from 'axios';
import config from '../../config.js'; // Import your config file

class Api {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: config.apiHunter,
    });
  }

  async googleAuth(userData) {
    try {
      const response = await axios.post('/hunter/google-auth', userData);
      return response.data;
    } catch (error) {
      console.error('Error during Google Auth:', error);
      throw error; // Re-throw the error to be handled by the component
    }
  }


  async registerUser(userData) {
    try {
      const response = await this.axiosInstance.post('/hunter/register', userData);
      return response.data;
    } catch (error) {
      console.error('Error registering user:', error);
      throw error;
    }
  }

  async loginUser(userData) {
    try {
      const response = await this.axiosInstance.post('/hunter/login', userData);
      return response.data;
    } catch (error) {
      console.error('Error logging in user:', error);
      throw error;
    }
  }

  async getBroadcastLocations() {
    try {
      const response = await this.axiosInstance.get('/hunter/get_broadcast_locations');
      return response.data;
    } catch (error) {
      console.error('Error fetching broadcast locations:', error);
      throw error;
    }
  }

  async getAllUsersAndDevices() {
    try {
      const response = await this.axiosInstance.get('/hunter/get_all_users_and_devices');
      return response.data;
    } catch (error) {
      console.error('Error fetching user and device data:', error);
      throw error;
    }
  }

  async deleteAllPositions() {
    try {
      const response = await this.axiosInstance.post('/hunter/delete_all_positions');
      return response.data; // Or handle the response as needed
    } catch (error) {
      console.error('Error deleting all positions:', error);
      throw error;
    }
  }

  async addAnimal(animalData) {
    try {
      const response = await this.axiosInstance.post('/hunter/add_animal', animalData);
      return response.data;
    } catch (error) {
      console.error('Error adding animal:', error);
      throw error;
    }
  }

  async removeAnimal(animalId) {
    try {
      const response = await this.axiosInstance.delete(`/hunter/remove_animal/${animalId}`);
      return response.data;
    } catch (error) {
      console.error('Error removing animal:', error);
      throw error;
    }
  }

  async getAnimals() {
    try {
      const response = await this.axiosInstance.get('/hunter/get_animals');
      return response.data;
    } catch (error) {
      console.error('Error fetching animals:', error);
      throw error;
    }
  }

  async getUserData(token) { // Add this method
    try {
      const response = await this.axiosInstance.get('/hunter/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
    }
  }

  async editUserName(name, token) { // Add this method
    try {
      const response = await this.axiosInstance.put('/hunter/user_edit_name', { UserName: name }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error updating user name:', error);
      throw error;
    }
  }

  async uploadUserPhoto(file, token) { // Add this method
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await this.axiosInstance.post('/hunter/upload_user_photo', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error uploading user photo:', error);
      throw error;
    }
  }






  async getHuntGroups(token) {
    try {
      const response = await this.axiosInstance.get('/huntgroups', { // Use this.axiosInstance
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching hunt groups:', error);
      throw error;
    }
  }

  async getHuntGroup(id, token) {
    try {
      const response = await this.axiosInstance.get(`/huntgroups/${id}`, { // Use this.axiosInstance
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching hunt group:', error);
      throw error;
    }
  }


  async getHuntGroupMembers(huntGroupId, token) {
    try {
      const response = await this.axiosInstance.get(`/huntgroups/${huntGroupId}/members`, { // Adjust the endpoint if necessary
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching hunt group members:', error);
      throw error;
    }
  }

  async joinHuntGroup(id, inviteCode, token) {
    try {
      const response = await this.axiosInstance.post(`/huntgroups/${id}/join`, { inviteCode }, { // Use this.axiosInstance
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error joining hunt group:', error);
      throw error;
    }
  }


  // In api.js

  async getMessages(huntGroupId, token) {
    try {
      const response = await this.axiosInstance.get(`/huntgroups/${huntGroupId}/messages`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('API response getMessages:', response.data);  // Log the response for debugging
      return response.data;
    } catch (error) {
      console.error('Error fetching messages:', error);
      throw error;
    }
  }

  async sendMessage(huntGroupId, message, token) {
    try {
      const response = await this.axiosInstance.post(`/huntgroups/${huntGroupId}/messages`, message, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error sending message:', error);
      throw error;
    }
  }

  async addAnimalSpotting(spottingData, token) {
    try {
      const response = await this.axiosInstance.post(`/huntgroups/${spottingData.huntGroupId}/spottings`, spottingData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error adding animal spotting:', error);
      throw error;
    }
  }



  async getAnimalSpottings(huntGroupId, token) {
    try {
      const response = await this.axiosInstance.get(`/huntgroups/${huntGroupId}/spottings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('API response getAnimalSpottings:', response.data);  // Log the response for debugging
      return response.data;
    } catch (error) {
      console.error('Error fetching animal spottings:', error);
      throw error;
    }
  }

  async removeAnimalSpotting(huntGroupId, spottingId, token) {
    try {
      const response = await this.axiosInstance.delete(`/huntgroups/${huntGroupId}/spottings/${spottingId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error removing animal spotting:', error);
      throw error;
    }
  }

  async addHuntPosition(huntGroupId, positionData, token) {
    try {
      const response = await this.axiosInstance.post(`/huntgroups/${huntGroupId}/positions`, positionData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error adding hunt position:', error);
      throw error;
    }
  }

  async getHuntPositions(huntGroupId, token) {
    try {
      const response = await this.axiosInstance.get(`/huntgroups/${huntGroupId}/positions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('API response getHuntPositions:', response.data);  // Log the response for debugging
      return response.data;
    } catch (error) {
      console.error('Error fetching hunt positions:', error);
      throw error;
    }
  }




  // In api.js

  // ... (existing code) ...

  // Update createHuntGroup method to include huntingGroundId
  async createHuntGroup(name, huntingGroundId, token) {
    try {
      const response = await this.axiosInstance.post('/huntgroups/create', { name, huntingGroundId }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error creating hunt group:', error);
      throw error;
    }
  }

  async getHuntingGrounds(token) {
    try {
      const response = await this.axiosInstance.get('/huntgroups/hunting_grounds', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching hunting grounds:', error);
      throw error;
    }
  }

  async getHuntingGroundForHuntGroup(huntGroupId, token) {
    try {
      const response = await this.axiosInstance.get(`/huntgroups/${huntGroupId}/hunting_ground`, { // Replace with your actual endpoint
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching hunting ground for hunt group:', error);
      throw error;
    }
  }


  async createHuntingGround(name, type, coordinates, token) {
    try {
      const response = await this.axiosInstance.post('/huntgroups/create_hunting_ground', { name, type, coordinates }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error creating hunting ground:', error);
      throw error;
    }
  }

  async deleteHuntingGround(huntingGroundId, token) {
    try {
      const response = await this.axiosInstance.delete(`/huntgroups/hunting_grounds/${huntingGroundId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting hunting ground:', error);
      throw error;
    }
  }


  async inviteUserToHuntGroup(huntGroupId, inviteCode, token) {
    try {
      const response = await this.axiosInstance.post(`/huntgroups/${huntGroupId}/invite`, { inviteCode }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error inviting user to hunt group:', error);
      throw error;
    }
  }

  async removeUserFromHuntGroup(huntGroupId, userId, token) {
    try {
      const response = await this.axiosInstance.delete(`/huntgroups/${huntGroupId}/members/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error removing user from hunt group:', error);
      throw error;
    }
  }



  // Add API methods for assigning/removing hunting grounds from hunt groups

  async assignHuntingGroundToHuntGroup(huntGroupId, huntingGroundId, token) {
    try {
      const response = await this.axiosInstance.post(`/huntgroups/${huntGroupId}/hunting_grounds/${huntingGroundId}/assign`, {}, { // You might need to adjust the endpoint
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error assigning hunting ground to hunt group:', error);
      throw error;
    }
  }

  // Get assigned hunting grounds for a hunt group
  async getAssignedHuntingGrounds(huntGroupId, token) {
    try {
      const response = await this.axiosInstance.get(`/huntgroups/${huntGroupId}/hunting_grounds`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching assigned hunting grounds:', error);
      throw error;
    }
  }

  async removeHuntingGroundFromHuntGroup(huntGroupId, huntingGroundId, token) {
    try {
      const response = await this.axiosInstance.delete(`/huntgroups/${huntGroupId}/hunting_grounds/${huntingGroundId}/remove`, { // You might need to adjust the endpoint
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error removing hunting ground from hunt group:', error);
      throw error;
    }
  }
  // ... (Add methods for invite/remove members and other hunting ground API calls) ...






  async deleteHuntGroup(huntGroupId, token) {
    try {
      const response = await this.axiosInstance.delete(`/huntgroups/${huntGroupId}`, { // Adjust the endpoint if needed
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting hunt group:', error);
      throw error;
    }
  }



  async inviteUserToHuntGroupByEmail(huntGroupId, email, token) {
    try {
      const response = await this.axiosInstance.post(`/huntgroups/${huntGroupId}/invite_by_email`, { email }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error inviting user to hunt group:', error);
      throw error;
    }
  }



  async getHuntGroupInvitations(token) {
    try {
      const response = await this.axiosInstance.get('/huntgroups/invitations', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching hunt group invitations:', error);
      throw error;
    }
  }





  async acceptHuntGroupInvitation(huntGroupId, token) {
    try {
      const response = await this.axiosInstance.post(`/huntgroups/${huntGroupId}/accept`, {}, { // Adjust the endpoint if needed
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error accepting hunt group invitation:', error);
      throw error;
    }
  }
  
  async declineHuntGroupInvitation(huntGroupId, token) {
    try {
      const response = await this.axiosInstance.post(`/huntgroups/${huntGroupId}/decline`, {}, { // Adjust the endpoint if needed
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error declining hunt group invitation:', error);
      throw error;
    }
  }


















} //api.js END

const api = new Api();
export default api;