// src/pages/indexPublic.js

import HomePublic from "./HomePublic"; 

import Login from "./Login";
import Register from "./Register";
import ForgetPass from "./ForgetPass";




const routes = [
  
  { path: '/', component: <HomePublic />},
  { path: '/login', component: <Login />},
  { path: '/register', component: <Register />},
  { path: '/forgetPass', component: <ForgetPass />},




]

export default routes;