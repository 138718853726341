// src/pages/UserProfilePrivate.jsx
import React, { useState, useEffect } from 'react';
import backgroundImage from '../images/bg.jpeg';
import api from '../components/Hunt/api';
import '../styles/profile.css';
import defaultProfileImage from '../images/default-profile.png';

function UserProfilePrivate() {
  const [userData, setUserData] = useState(null);
  const [name, setName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [profilePhotoPath, setProfilePhotoPath] = useState('');
  const [isLoadingName, setIsLoadingName] = useState(false);
  const [isLoadingPhoto, setIsLoadingPhoto] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoadingName(true);
      const token = localStorage.getItem('token');
      console.log("auth token: ", token); // Log the entire response here
      try {
        const response = await api.getUserData(token);

        if (response.userData) {
          setUserData(response.userData);
          setName(response.userData.userName);
          
          console.log("API Response:", response); // Log the entire response here


  
          // Use the Base64 string directly to create the image URL
          if (response.profilePhoto) { 
            setProfilePhotoPath(`data:image/jpeg;base64,${response.profilePhoto}`); 
          }
        } else {
          console.error("UserData is missing in the API response:", response);
          setError("Failed to fetch user data. Please try again later.");
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError(error.message);
      } finally {
        setIsLoadingName(false);
      }
    };

    fetchUserData();
  }, []);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePhotoChange = async (event) => {
    setSelectedFile(event.target.files[0]);

    if (event.target.files[0]) {
      setIsLoadingPhoto(true);
      setError(null);
      const token = localStorage.getItem('token');

      try {
        const response = await api.uploadUserPhoto(event.target.files[0], token);

        // Update profilePhotoPath with the new image data
        const base64String = btoa(
          new Uint8Array(response.profilePhoto).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        );
        setProfilePhotoPath(`data:image/jpeg;base64,${base64String}`);

        console.log('User photo updated successfully!');
      } catch (error) {
        console.error('Error updating photo:', error);
        setError(error.message);
      } finally {
        setIsLoadingPhoto(false);
      }
    }
  };

  const handleImageClick = () => {
    document.getElementById('photo').click();
  };

  const handleNameSubmit = async (event) => {
    event.preventDefault();
    setIsLoadingName(true);
    setError(null);
    const token = localStorage.getItem('token');

    try {
      await api.editUserName(name, token);
      console.log('Username updated successfully!');
    } catch (error) {
      console.error('Error updating username:', error);
      setError(error.message);
    } finally {
      setIsLoadingName(false);
    }
  };

  if (isLoadingName) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!userData) {
    return <div>No user data found.</div>;
  }

  return (
    <div className='inner-page'>
      <section className="page-title">
        <div className="overlay"></div>
      </section>

      <section
        className="tf-section project-info"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="profile-container">
                <div className="profile-header">
                  <img
                    src={profilePhotoPath || defaultProfileImage}
                    alt="Profile"
                    className="profile-image"
                    onClick={handleImageClick}
                    style={{ cursor: 'pointer' }}
                  />
                  <h2>{name}</h2>
                </div>

                <div className="profile-forms">
                  <form onSubmit={handleNameSubmit} className="profile-form">
                    <div className="form-group">
                      <label htmlFor="name">Name:</label>
                      <input
                        type="text"
                        id="name"
                        className="form-control"
                        value={name}
                        onChange={handleNameChange}
                      />
                    </div>
                    <button type="submit" className="btn btn-primary" disabled={isLoadingName}>
                      {isLoadingName ? 'Saving...' : 'Update Name'}
                    </button>
                  </form>

                  <form className="profile-form"> {/* No onSubmit handler */}
                    <div className="form-group">
                      <input
                        type="file"
                        id="photo"
                        className="form-control-file"
                        onChange={handlePhotoChange}
                        hidden
                      />
                    </div>
                    {/* No submit button */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default UserProfilePrivate;