// src/pages/Login.jsx
import React, { useState, useContext } from 'react'; // Import useContext
import { AuthContext } from '../AuthContext';
import api from '../components/mapbox/api'; // Import the API class
import { useNavigate, Link } from 'react-router-dom';
import backgroundImage from '../images/bg.jpeg'; // Adjust the path if needed
import LoginGoogle from './LoginGoogle';


function Login(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const { login } = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false); // State for loading
    const navigate = useNavigate();


    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null); // Clear previous errors

        try {
            await login(email, password); // Call the login function from context
            navigate('/');
        } catch (error) {
            // Catch the re-thrown error and display the message
            setError(error.message || 'Invalid email or password');
            console.error('Login failed:', error);
        }
    };


    return (
        <div className='inner-page'>
            <section className="page-title">
                <div className="overlay"></div>
            </section>



            <section className="tf-section project-info" style={{ backgroundImage: `url(${backgroundImage})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <form
                                onSubmit={handleSubmit}> {/* Add onSubmit handler */}
                                <div className="project-info-form form-login">
                                    <h6 className="title">Login</h6>
                                    <h6 className="title show-mobie">
                                        <Link to="/register">Register</Link>
                                    </h6>
                                    <h6 className="title link">
                                        <Link to="/register">Register</Link>
                                    </h6>
                                    <p>Enter your credentials to access your account</p>
                                    {error && <div className="error-message">{error}</div>} {/* Display error message */}
                                    <div className="form-inner">
                                        <fieldset>
                                            <label>Email address *</label>
                                            <input
                                                type="email"
                                                placeholder="Your email"
                                                required

                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </fieldset>
                                        <fieldset>
                                            <label>Password *</label>
                                            <input
                                                type="password"
                                                placeholder="Your password"
                                                required
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </fieldset>
                                    </div>
                                    <Link to="/forgetPass" className="fogot-pass">
                                        Fogot password?
                                    </Link>
                                </div>

                                <LoginGoogle /> {/* Add the Google Sign-In button */}


                                <div className="wrap-btn">
                                    <button type="submit" className="tf-button style2" disabled={isLoading}> {/* Disable button while loading */}
                                        {isLoading ? 'Logging in...' : 'Login'} {/* Show loading text */}
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </section>






        </div>
    );
}

export default Login;