// src/components/Hunt/HuntGroup/CreateHuntGroup.js

import React, { useState, useEffect } from 'react';
import api from '../api'; // Import your API module

const CreateHuntGroup = ({ onCreate }) => {
  const [name, setName] = useState('');
  const [huntingGroundId, setHuntingGroundId] = useState(null); // To store selected hunting ground ID
  const [huntingGrounds, setHuntingGrounds] = useState([]); // To store the user's hunting grounds
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHuntingGrounds = async () => {
      try {
        const token = localStorage.getItem('token');
        const grounds = await api.getHuntingGrounds(token); // Fetch hunting grounds from API
        setHuntingGrounds(grounds);
      } catch (error) {
        console.error('Error fetching hunting grounds:', error);
        setError('Failed to fetch hunting grounds.');
      }
    };

    fetchHuntingGrounds();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!huntingGroundId) {
      setError('Please select a hunting ground.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const newGroup = await api.createHuntGroup(name, huntingGroundId, token); 
      onCreate(newGroup);
      setName('');
      setHuntingGroundId(null);
    } catch (error) {
      console.error('Error creating hunt group:', error);
      setError('Failed to create hunt group.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="project-info-form"> {/* Use project-info-form class */}
      <div>
        <label htmlFor="name">Hunt Group Name:</label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="huntingGround">Hunting Ground:</label>
        <select
          id="huntingGround"
          value={huntingGroundId || ''} // Set value to empty string if null
          onChange={(e) => setHuntingGroundId(parseInt(e.target.value, 10))}
          required
        >
          <option value="">Select a hunting ground</option>
          {huntingGrounds.map((ground) => (
            <option key={ground.huntingGroundId} value={ground.huntingGroundId}>
              {ground.name}
            </option>
          ))}
        </select>
      </div>
      {error && <div className="error-message">{error}</div>} {/* Display error message */}
      <button type="submit" className="tf-button">Create</button> {/* Use tf-button class */}
    </form>
  );
};

export default CreateHuntGroup;